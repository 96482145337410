.animation_first{
    width: 95%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.snowanimation{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.top_icon_btn{
width: 20px;
height: 20px;
position: absolute;
right: 40px;
width: 40px;
background-color: #053D33;
color: #FFF;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
}
